import { PatientData } from "@/pages/modern-emr"
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
  Font,
} from "@react-pdf/renderer"

Font.register({
  family: "Open Sans",
  fonts: [
    {
      src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf",
    },
    {
      src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf",
      fontWeight: 600,
    },
  ],
})

// Reference font

const styles = StyleSheet.create({
  page: {
    padding: 30,
    display: "flex",
    flexDirection: "column",
    minHeight: "100%",
    backgroundColor: "#ffd700",
    // fontFamily: "Roboto",
    fontFamily: "Open Sans",
  },
  header: {
    marginBottom: 5,
  },
  headerText: {
    fontSize: 24,
    fontWeight: 900,
    textAlign: "center",
    // marginBottom: 5,
  },
  subHeader: {
    fontSize: 10,
    textAlign: "center",
    // marginBottom: 2,
  },
  title: {
    fontSize: 14,
    fontWeight: 900,
    marginBottom: 10,
    padding: 2,
    border: "1 solid black",
    borderRadius: 5,
    marginLeft: "auto",
    marginRight: "auto",
  },
  row: {
    flexDirection: "row",
    marginBottom: 10,
    gap: 15,
  },
  label: {
    // width: 80,
    fontSize: 12,
    fontWeight: 800,
    // margin: 3,
  },
  content: {
    fontSize: 12,
  },
  value: {
    flex: 1,
    fontSize: 12,
    borderBottom: "2 solid black",
    textAlign: "center",
  },
  section: {
    marginTop: 15,
  },
  footer: {
    fontSize: 8,
    marginTop: 10,
  },
  barcodeSection: {
    marginTop: 10,
    marginBottom: 10,
  },
  diagnosisSection: {
    marginTop: 30,
    marginBottom: 20,
  },
  diagnosisLabel: {
    fontSize: 12,
    fontWeight: "bold",
    marginBottom: 10,
  },
  diagnosisValue: {
    borderBottom: "1 solid black",
    minHeight: 25,
    marginBottom: 5,
  },
  signatureTable: {
    marginTop: "auto",
    width: "50%",
    alignSelf: "flex-end",
    border: "1 solid black",
  },
  tableRow: {
    flexDirection: "row",
    borderBottom: "1 solid black",
    minHeight: 25,
  },
  tableCell: {
    padding: 5,
    fontSize: 10,
    flex: 1,
    borderRight: "1 solid black",
  },
  tableCellLast: {
    padding: 5,
    fontSize: 10,
    flex: 1,
  },
})

export default function DentalRecord({
  patientData,
}: {
  patientData: PatientData
}) {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        {/* Header */}
        <View style={styles.header}>
          <Image
            src="360_F_514364850_xLOQX6SOY2qcjAIcTowsi3xYvHmhmvs0.jpg"
            style={{
              width: 50,
              height: 50,
              // alignSelf: "right",
              marginLeft: "auto",
              marginBottom: 10,
            }}
          />
          <Text
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              fontSize: 12,
            }}
          >
            No......................../20
          </Text>
          <Text style={styles.headerText}>JSS DENTAL COLLEGE & HOSPITAL</Text>
          <Text style={styles.subHeader}>ISO 9001:2015 CERTIFIED</Text>
          <Text style={styles.subHeader}>
            Sri Shivarathreeshwara Nagara, Mysuru - 570015
          </Text>
          <Text style={styles.subHeader}>
            Karnataka, India, Phone: +91-821-2548349/350
          </Text>
          <Text style={styles.subHeader}>
            e-mail: jss_dch@yahoo.co.in Website: www.jssuni.edu.in
          </Text>
        </View>

        <View
          style={{
            borderBottom: "0.5px solid gray",
            margin: " 5px 0",
          }}
        />

        {/* Title */}
        <Text style={styles.title}>OUT PATIENT RECORD</Text>

        {/* Patient Info - First Row */}
        <View style={styles.row}>
          <View style={{ flexDirection: "row", flex: 1 }}>
            <Text style={styles.label}>Name:</Text>
            <Text style={styles.value}>{patientData.name}</Text>
          </View>
          <View style={{ flexDirection: "row", flex: 1 }}>
            <Text style={styles.label}>Address:</Text>
            <Text style={styles.value}>{patientData.address}</Text>
          </View>
        </View>

        {/* Additional Address Line */}
        <View style={styles.row}>
          <View style={{ flexDirection: "row", flex: 1 }}>
            <Text style={styles.label}>Age:</Text>
            <Text style={styles.value}>{patientData.age}</Text>
          </View>
          <View style={{ flex: 1 }}>
            <Text
              style={[
                {
                  borderBottom: "2px solid black",
                  width: "80%",
                  marginTop: 15,
                  marginLeft: "auto",
                },
              ]}
            ></Text>
          </View>
        </View>

        {/* Patient Info - Second Row */}
        <View style={styles.row}>
          <View style={{ flexDirection: "row", flex: 1 }}>
            <Text style={styles.label}>Occupation:</Text>
            <Text style={styles.value}>{patientData.occupation}</Text>
          </View>
          <View style={{ flexDirection: "row", flex: 1 }}>
            <Text style={styles.label}>Phone No:</Text>
            <Text style={styles.value}>{patientData.phone}</Text>
          </View>
        </View>

        <View style={{ borderBottom: "0.5px solid gray", margin: " 5px 0" }} />

        {/* Clinical Sections */}

        <View style={[styles.section, { marginBottom: 70 }]}>
          <View style={{ flexDirection: "row", flex: 1, gap: 15 }}>
            <Text style={styles.label}>Case:</Text>
            <Text style={styles.content}>{patientData.case}</Text>
          </View>
        </View>

        <View style={[styles.section, { marginBottom: 70 }]}>
          <View style={{ flexDirection: "row", flex: 1, gap: 15 }}>
            <Text style={styles.label}>History:</Text>
            <Text style={styles.content}>{patientData.history}</Text>
          </View>
        </View>

        <View style={[styles.section, { marginBottom: 70 }]}>
          <View style={{ flexDirection: "row", flex: 1, gap: 15 }}>
            <Text style={[styles.label]}>Clinical Findings:</Text>
            <Text style={styles.content}>{patientData.clinicalFindings}</Text>
          </View>
        </View>

        {/* Spacer to push footer to bottom */}
        <View style={{ flex: 1 }} />

        {/* Footer Section */}
        <View
          style={{
            borderBottom: "0.5px solid gray",
            margin: "5px 0",
          }}
        />

        <View style={styles.footer}>
          <Text>Note:</Text>
          <Text>
            • Please visit Public Health Dentistry (Room No. 8) for free Dental
            health Education & Preventive care instructions.
          </Text>
          <Text>
            • Geriatric Clinic Services are available for elderly patients.
          </Text>
        </View>
      </Page>

      {/* Page 2 */}
      <Page size="A4" style={styles.page}>
        <View style={{ marginTop: "45%", marginBottom: 30 }}>
          <View style={{ flexDirection: "row", flex: 1, gap: 15 }}>
            <Text style={styles.label}>Provisional Diagnosis :</Text>
            <Text style={styles.content}>
              {patientData.provisionalDiagnosis}
            </Text>
          </View>
        </View>

        <View style={[styles.diagnosisSection]}>
          <View style={{ flexDirection: "row", flex: 1, gap: 15 }}>
            <Text style={styles.diagnosisLabel}>Differential Diagnosis :</Text>
            <Text style={styles.content}>
              {patientData.diferentialDiagnosis}
            </Text>
          </View>
        </View>

        <View style={styles.diagnosisSection}>
          <View style={{ flexDirection: "row", flex: 1, gap: 15 }}>
            <Text style={styles.diagnosisLabel}>Investigations :</Text>
            <Text style={styles.content}>{patientData.investigations}</Text>
          </View>
        </View>

        <View style={[styles.diagnosisSection, { marginTop: "80px" }]}>
          <View style={{ flexDirection: "row", flex: 1, gap: 15 }}>
            <Text style={styles.diagnosisLabel}>Final Diagnosis :</Text>
            <Text style={styles.content}>{patientData.finalDiagnosis}</Text>
          </View>
        </View>

        <View style={styles.diagnosisSection}>
          <View style={{ flexDirection: "row", flex: 1, gap: 15 }}>
            <Text style={styles.diagnosisLabel}>Referral :</Text>
            <Text style={styles.content}>{patientData.referral}</Text>
          </View>
        </View>

        <View style={styles.signatureTable}>
          <View style={styles.tableRow}>
            <Text style={styles.tableCell}>Name</Text>
            <Text style={styles.tableCellLast}></Text>
          </View>
          <View style={styles.tableRow}>
            <Text style={styles.tableCell}>Registration No</Text>
            <Text style={styles.tableCellLast}></Text>
          </View>
          <View style={styles.tableRow}>
            <Text style={styles.tableCell}>Designation</Text>
            <Text style={styles.tableCellLast}></Text>
          </View>
          <View style={styles.tableRow}>
            <Text style={styles.tableCell}>Date/Time</Text>
            <Text style={styles.tableCellLast}></Text>
          </View>
          <View style={[styles.tableRow, { borderBottom: "none" }]}>
            <Text style={styles.tableCell}>Signature</Text>
            <Text style={styles.tableCellLast}></Text>
          </View>
        </View>
      </Page>
    </Document>
  )
}
