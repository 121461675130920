import { useState, useEffect } from "react"
import { Card, CardContent } from "@/components/ui/card"
import { Input } from "@/components/ui/input"
import { Textarea } from "@/components/ui/textarea"
import { Button } from "@/components/ui/button"
import { Label } from "@/components/ui/label"
import { Brain } from "lucide-react"
import { VoiceRecorder } from "@/components/voice-recorder"
import { AIInsights } from "@/components/ai-insights"
import { PDFDownloadLink } from "@react-pdf/renderer"
import DentalRecord from "@/components/emr-pdf"
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "@/components/ui/dialog"
import { toast } from "sonner"
import { config } from "@/config"
import { useAuth } from "@/context/AuthContext"

export type PatientData = {
  id: string
  name: string
  address?: string
  age: string
  occupation?: string
  date?: string
  phone?: string
  case: string
  history: string
  clinicalFindings: string
  provisionalDiagnosis: string
  diferentialDiagnosis: string
  investigations: string
  finalDiagnosis: string
  referral: string
}

export type TranscriptData = {
  id: string
  emr: PatientData
  summary?: string
  transcript?: string
}

export default function ModernEMR() {
  const [formData, setFormData] = useState<PatientData>({
    id: "",
    name: "",
    address: "",
    age: "",
    occupation: "",
    date: "",
    phone: "",
    case: "",
    history: "",
    clinicalFindings: "",
    provisionalDiagnosis: "",
    diferentialDiagnosis: "",
    investigations: "",
    finalDiagnosis: "",
    referral: "",
  })
  const [saved, setSaved] = useState(true)
  const [showTranscriptModal, setShowTranscriptModal] = useState(false)
  const [transcriptList, setTranscriptList] = useState<TranscriptData[]>([])
  const { user } = useAuth()

  const isFormEmpty = () => {
    return Object.values(formData).every((value) => value.trim() === "")
  }

  const updateFormWithAudioData = (data: TranscriptData) => {
    const formatField = (field: string[] | string | undefined) => {
      if (Array.isArray(field)) return field.join(", ")
      return field || ""
    }

    const appendField = (
      existing: string,
      newData: string[] | string | undefined
    ) => {
      if (!existing) return formatField(newData)
      if (!newData) return existing
      return `${existing}, ${formatField(newData)}`
    }

    setFormData((prevData) => ({
      ...prevData,
      id: data.id || prevData.id,
      name: data.emr.name || prevData.name,
      age: String(Number(data.emr.age) || prevData.age),
      address: data.emr.address || prevData.address,
      occupation: data.emr.occupation || prevData.occupation,
      date: data.emr.date || prevData.date,
      phone: data.emr.phone || prevData.phone,
      case: appendField(prevData.case, data.emr.case),
      history: appendField(prevData.history, data.emr.history),
      clinicalFindings: appendField(
        prevData.clinicalFindings,
        data.emr.clinicalFindings
      ),
      provisionalDiagnosis: appendField(
        prevData.provisionalDiagnosis,
        data.emr.provisionalDiagnosis
      ),
      diferentialDiagnosis: appendField(
        prevData.diferentialDiagnosis,
        data.emr.diferentialDiagnosis
      ),
      investigations: appendField(
        prevData.investigations,
        data.emr.investigations
      ),
      finalDiagnosis: appendField(
        prevData.finalDiagnosis,
        data.emr.finalDiagnosis
      ),
      referral: appendField(prevData.referral, data.emr.referral),
    }))
    setSaved(false)
  }

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }))
    setSaved(false)
  }

  useEffect(() => {
    if (!saved) {
      const timer = setTimeout(() => {
        setSaved(true)
      }, 1000)
      return () => clearTimeout(timer)
    }
  }, [saved])

  useEffect(() => {
    if (showTranscriptModal) {
      const fetchTranscripts = async () => {
        try {
          const response = await fetch(
            `${config.BASE_URL}/process-consultation/${user?.uid}`
          )
          if (!response.ok) {
            throw new Error("Failed to fetch transcripts")
          }
          const data = await response.json()

          setTranscriptList(data || [])
        } catch (error) {
          console.error("Error fetching transcripts:", error)
        }
      }
      fetchTranscripts()
    }
  }, [showTranscriptModal])

  const handleTranscriptSelection = (transcript: TranscriptData) => {
    updateFormWithAudioData(transcript)
    setShowTranscriptModal(false)
    toast.success("Form updated with selected transcript")
  }

  console.log(transcriptList)
  return (
    <div className="min-h-[90vh] bg-gray-50">
      <div className="container mx-auto p-6 h-[calc(100vh-4rem)]">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 h-full">
          {/* Left Column - AI Assistance */}
          <div className="space-y-6 h-full flex flex-col">
            <div className="flex items-center gap-2">
              <Brain className="h-6 w-6 text-primary" />
              <h2 className="text-xl font-semibold">AI Clinical Assistant</h2>
            </div>

            {/* Voice Recording */}
            <Card>
              <CardContent className="p-6">
                <VoiceRecorder
                  updateFormWithAudioData={updateFormWithAudioData}
                  transcriptId={formData.id}
                  user={user}
                />
              </CardContent>
            </Card>

            {/* AI Insights */}
            <div className="flex-grow">
              <AIInsights
                symptoms={formData.provisionalDiagnosis}
                conditions={formData.diferentialDiagnosis}
              />
            </div>
          </div>

          {/* Right Column - EMR Form */}
          <div className="space-y-6 h-full overflow-y-auto">
            <div className="flex flex-row items-center justify-between">
              <h2 className="text-xl font-semibold">
                Electronic Medical Record
              </h2>
              <div className="flex items-center gap-2">
                <Button disabled={isFormEmpty()} className="">
                  <PDFDownloadLink
                    document={<DentalRecord patientData={formData} />}
                    fileName="test.pdf"
                  >
                    Generate PDF!
                  </PDFDownloadLink>
                </Button>
                <Button
                  variant="outline"
                  onClick={() => setShowTranscriptModal(true)}
                >
                  Transcript List
                </Button>
              </div>
            </div>

            <Card>
              <CardContent className="p-6 space-y-6">
                <div className="grid grid-cols-2 gap-4">
                  <div className="space-y-2">
                    <input type="hidden" name="id" value={formData.id} />
                    <Label htmlFor="name">Patient Name</Label>
                    <Input
                      id="name"
                      name="name"
                      value={formData.name}
                      onChange={handleInputChange}
                      className="transition-all duration-200 focus:ring-2 focus:ring-offset-2"
                      placeholder="Enter patient name"
                    />
                  </div>
                  <div className="space-y-2">
                    <Label htmlFor="age">Age</Label>
                    <Input
                      id="age"
                      name="age"
                      type="number"
                      value={formData.age}
                      onChange={handleInputChange}
                      className="transition-all duration-200 focus:ring-2 focus:ring-offset-2"
                      min="0"
                      max="120"
                      placeholder="Enter age"
                    />
                  </div>
                </div>

                <div className="grid grid-cols-2 gap-4">
                  <div className="space-y-2">
                    <Label htmlFor="address">Address</Label>
                    <Textarea
                      id="address"
                      name="address"
                      value={formData.address}
                      onChange={handleInputChange}
                      placeholder="Enter address"
                    />
                  </div>
                  <div className="space-y-2">
                    <Label htmlFor="phone">Phone</Label>
                    <Input
                      id="phone"
                      name="phone"
                      value={formData.phone}
                      onChange={handleInputChange}
                      placeholder="Enter phone number"
                    />
                  </div>
                </div>

                <div className="grid grid-cols-2 gap-4">
                  <div className="space-y-2">
                    <Label htmlFor="occupation">Occupation</Label>
                    <Input
                      id="occupation"
                      name="occupation"
                      value={formData.occupation}
                      onChange={handleInputChange}
                      placeholder="Enter occupation"
                    />
                  </div>
                  <div className="space-y-2">
                    <Label htmlFor="date">Date</Label>
                    <Input
                      id="date"
                      name="date"
                      type="date"
                      value={formData.date}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>

                <div className="space-y-2">
                  <Label htmlFor="case">Case Description</Label>
                  <Textarea
                    id="case"
                    name="case"
                    value={formData.case}
                    onChange={handleInputChange}
                    placeholder="Enter case description"
                    className="min-h-[100px]"
                  />
                </div>

                <div className="space-y-2">
                  <Label htmlFor="history">Medical History</Label>
                  <Textarea
                    id="history"
                    name="history"
                    value={formData.history}
                    onChange={handleInputChange}
                    placeholder="Enter medical history"
                    className="min-h-[100px]"
                  />
                </div>

                <div className="space-y-2">
                  <Label htmlFor="clinicalFindings">Clinical Findings</Label>
                  <Textarea
                    id="clinicalFindings"
                    name="clinicalFindings"
                    value={formData.clinicalFindings}
                    onChange={handleInputChange}
                    placeholder="Enter clinical findings"
                    className="min-h-[100px]"
                  />
                </div>

                <div className="grid grid-cols-2 gap-4">
                  <div className="space-y-2">
                    <Label htmlFor="provisionalDiagnosis">
                      Provisional Diagnosis
                    </Label>
                    <Textarea
                      id="provisionalDiagnosis"
                      name="provisionalDiagnosis"
                      value={formData.provisionalDiagnosis}
                      onChange={handleInputChange}
                      placeholder="Enter provisional diagnosis"
                    />
                  </div>
                  <div className="space-y-2">
                    <Label htmlFor="diferentialDiagnosis">
                      Differential Diagnosis
                    </Label>
                    <Textarea
                      id="diferentialDiagnosis"
                      name="diferentialDiagnosis"
                      value={formData.diferentialDiagnosis}
                      onChange={handleInputChange}
                      placeholder="Enter differential diagnosis"
                    />
                  </div>
                </div>

                <div className="space-y-2">
                  <Label htmlFor="investigations">Investigations</Label>
                  <Textarea
                    id="investigations"
                    name="investigations"
                    value={formData.investigations}
                    onChange={handleInputChange}
                    placeholder="Enter investigations"
                    className="min-h-[100px]"
                  />
                </div>

                <div className="space-y-2">
                  <Label htmlFor="finalDiagnosis">Final Diagnosis</Label>
                  <Textarea
                    id="finalDiagnosis"
                    name="finalDiagnosis"
                    value={formData.finalDiagnosis}
                    onChange={handleInputChange}
                    placeholder="Enter final diagnosis"
                    className="min-h-[100px]"
                  />
                </div>

                <div className="space-y-2">
                  <Label htmlFor="referral">Referral</Label>
                  <Textarea
                    id="referral"
                    name="referral"
                    value={formData.referral}
                    onChange={handleInputChange}
                    placeholder="Enter referral details"
                    className="min-h-[100px]"
                  />
                </div>
              </CardContent>
            </Card>
          </div>
        </div>
      </div>
      <Dialog open={showTranscriptModal} onOpenChange={setShowTranscriptModal}>
        <DialogContent className="sm:max-w-[600px] w-full">
          <DialogHeader>
            <DialogTitle>Transcripts</DialogTitle>
          </DialogHeader>
          <div className="space-y-4 py-4">
            {transcriptList.length > 0 ? (
              transcriptList.map((transcript) => (
                <div
                  key={transcript.id}
                  className="cursor-pointer hover:bg-gray-100 p-2 rounded"
                  onClick={() => handleTranscriptSelection(transcript)}
                >
                  <p className="font-semibold">
                    Transcript ID: {transcript.id}
                  </p>
                  <p className="text-sm">
                    {transcript.transcript ||
                      transcript.summary ||
                      transcript.emr.case.slice(0, 50) + "..."}
                  </p>
                </div>
              ))
            ) : (
              <p className="text-gray-500">No transcripts found.</p>
            )}
          </div>
          <DialogFooter>
            <Button onClick={() => setShowTranscriptModal(false)}>Close</Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  )
}
